import * as React from 'react';

import { ISEO, IPageContext } from '../interfaces';
import { Meta } from '../components';

interface ISEOProps {
  data: {
    [dataType: string]: {
      title?: string;
      seo: ISEO;
    };
  };
  pageContext: IPageContext;
}

const withMeta = <P extends {}>(Component: React.ComponentType<P>) =>
  class WithMeta extends React.Component<P & ISEOProps> {
    render() {
      const { data, pageContext } = this.props;

      const dataType = Object.keys(data)[0];
      const seo =
        pageContext.slug === 'icelandair'
          ? null
          : dataType && data[dataType].seo;
      const title = dataType && data[dataType].title;

      return (
        <React.Fragment>
          {seo ? (
            <Meta seo={seo} langKey={pageContext.langKey} title={title} langSlugs={pageContext.langSlugs} />
          ) : null}
          <Component {...this.props} />
        </React.Fragment>
      );
    }
  };

export default withMeta;
